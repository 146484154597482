
import {apis} from "../../myEnv.json"
import axios from 'axios';
import {logOut} from './authReducer';
const ADDCAT_SUCCESS = 'main/addcat_success';
const ADDCAT_FAIL = 'main/addcat_fail';
const ADDUSER_SUCCESS = 'main/adduser_success';
const ADDUSER_FAIL = 'main/adduser_fail';
const UPDATEUSER_SUCCESS = 'main/updateuser_success';
const UPDATEUSER_FAIL = 'main/updateuser_fail';
const UPDATECAT_SUCCESS = 'main/updatecat_success';
const UPDATECAT_FAIL = 'main/updatecat_fail';
const CAT_CHECK_SUCCESS = 'main/cat_check_success';
const CAT_CHECK_FAIL = 'main/cat_check_fail';
const GET_CAT_SUCCESS = 'main/get_cat_success';
const GET_CAT_FAIL = 'main/get_cat_fail';
const GET_CAT_SUCCESS2 = 'main/get_cat_success2';
const GET_CAT_FAIL2 = 'main/get_cat_fail2';
const GET_CAT_DETAILS_SUCCESS = 'main/get_cat_details_success';
const GET_CAT_DETAILS_FAIL = 'main/get_cat_details_fail';
const MAIN_DATA_RESET = 'main/reset_mainData_success';
const CAT_DATA_RESET = 'main/reset_catData_success';
const RESET_SHOWCATDETAILS_SUCCESS ='main/reset_showCatDetails_success';
const RESET_MODALINTIALS_SUCCESS ='main/reset_modalIntials_success';
const RESET_ADDCATINTIALS_SUCCESS ='main/reset_addCatIntials_success';
const RESET_USERS_SUCCESS ='main/reset_users_success';
const DELETE_CAT_SUCCESS = 'main/deletecat_success';
const DELETE_CAT_FAIL = 'main/deletecat_fail';
const DELETE_ACT_SUCCESS = 'main/delete_act_success';
const DELETE_ACT_FAIL = 'main/delete_act_fail';
const APPROVE_ACT_SUCCESS = 'main/approve_act_success';
const APPROVE_ACT_FAIL = 'main/approve_act_fail';
const FETCH_USERS_SUCCESS = 'main/fetch_users_sucsess';
const FETCH_USERS_FAIL = 'main/fetch_users_fail';
const GET_USER_DETAILS_SUCCESS = 'main/get_user_details_success';
const GET_USER_DETAILS_FAIL = 'main/get_user_details_fail';
const GET_PROJ_DETAILS_SUCCESS = 'main/get_proj_details_success';
const GET_PROJ_DETAILS_FAIL = 'main/get_proj_details_fail';
const CHANGE_USER_STATUS_SUCCESS = 'main/change_user_status_success';
const CHANGE_USER_STATUS_FAIL = 'main/change_user_status_fail';
const CHANGE_PROJ_STATUS_SUCCESS = 'main/change_proj_status_success';
const CHANGE_PROJ_STATUS_FAIL = 'main/change_proj_status_fail';
const CHANGE_PASSWORD_SUCCESS = 'main/change_password_success';
const CHANGE_PASSWORD_FAIL = 'main/change_password_fail';
const RESET_USER_PASS_SUCCESS = 'main/reset_user_pass_success';
const GET_PROJECTS_SUCCESS = 'main/get_projects_success';
const GET_PROJECTS_FAIL = 'main/get_projects_fail';
const GET_ACT_SUCCESS =  'main/get_act_details_success';
const GET_ACT_FAIL =  'main/get_act_details_fail';

const GET_STAFFREGDATA_SUCCESS =  'main/GET_STAFFREGDATA_SUCCESS';
const GET_STAFFREGDATA_FAIL =  'main/GET_STAFFREGDATA_FAIL';
const ADD_DATA_SUCCESS = 'main/ADD_DATA_SUCCESS';
const ADD_DATA_FAIL = 'main/ADD_DATA_FAIL';
const GET_TEACHER_STUDENTS_SUCCESS= 'main/GET_TEACHER_STUDENTS_SUCCESS';
const GET_TEACHER_STUDENTS_FAIL= 'main/GET_TEACHER_STUDENTS_FAIL';
const STUDENT_DATA_REST = 'main/STUDENT_DATA_REST';

const ADD_MARKS_SUCCESS= 'main/ADD_MARKS_SUCCESS';
const ADD_MARKS_FAIL= 'main/ADD_MARKS_FAIL';
const MAIN_MARKS_RESET = 'main/MAIN_MARKS_RESET';

const CLASS_REPORTS_SUCCESS= 'main/CLASS_REPORTS_SUCCESS';
const CLASS_REPORTS_FAIL= 'main/CLASS_REPORTS_FAI';
const CLASS_REPORTS_RESET = 'main/CLASS_REPORTS_RESET';

const GETMYDATARESET = 'main/GETMYDATARESET';
const GETDATA_SUCCESS = 'main/GETDAT_SUCCESS';
const GETDATA_FAIL = 'main/GETDAT_FAIL';

const ADDMYDATARESET = 'main/ADDMYDATARESET';
const ADDMYDATA_SUCCESS = 'main/ADDMYDATA_SUCCESS';
const ADDMYDATA_FAIL = 'main/ADDMYDATA_FAIL';


const initilState = {
    msg: null,
    addCatStatus:null,
    addUserStatus:null,
    
    checkCatStatus:null,
    checkCatMsg:null,

    dataStatus:false,
    mainData:null,
    showDataStatus:null,
    showData:null,
    deleteStatus:null,
    updateStatus:null,
    updateData:null,
    catStatus:null,
    catData:null,
    changeStatus:null,
    changePassStatus:null,
    addDataStatus:null,
    getStudentsStatus:null,
    studentsData:null,
    addMarksStatus:null,

    reportStatus:null,
    path:null,
    getMyDataStatus:null,
    getMyData:null,

    addMyData:null,
    addMyDataStatus:null
};
//Reducer
export default(state = initilState, action) => {
    switch (action.type) {
        case MAIN_DATA_RESET:
            return{
                dataStatus:false,
                mainData:null,
                showDataStatus:null,
                showData:null,
                deleteStatus:null,
                updateStatus:null,
                updateData:null,
                changeStatus:null,
                addDataStatus:null,
            };
        case MAIN_MARKS_RESET:
            return {
                addMarksStatus:null,
            }
        case STUDENT_DATA_REST:
            return{
                getStudentsStatus:null,
                studentsData:null,
            };
        case CAT_DATA_RESET:
            return{
                catStatus:null,
                catData:null
            };
        case CLASS_REPORTS_RESET:
            return{
                reportStatus:null,
                path:null
            };
        case GETMYDATARESET:
            return{
                ...state,
                getMyDataStatus:null,
                getMyData:null
            };
        case ADDMYDATARESET:
            return{
                ...state,
                addMyData:null,
                addMyDataStatus:null
            }
        case ADDCAT_SUCCESS:
            return {
                ...state,
                addCatStatus:true,
                msg:action.payload.msg
            };
        case ADDCAT_FAIL:
            return {
                ...state,
                addCatStatus:false,
                msg:action.payload.msg
            };
        case UPDATECAT_SUCCESS:
        case UPDATEUSER_SUCCESS:
            return {
                ...state,
                updateStatus:true,
                msg:action.payload.msg,
                updateData:action.payload.mainData,
            };
        case UPDATECAT_FAIL:
        case UPDATEUSER_FAIL:   
            return {
                ...state,
                updateStatus:false,
                msg:action.payload.msg,
                updateData:action.payload.mainData,
            };
        case CAT_CHECK_SUCCESS:
            return {
                ...state,
                checkCatStatus:action.payload.error,
                checkCatMsg:action.payload.msg
            };
        case CAT_CHECK_FAIL:
            return {
                ...state,
                checkCatStatus:action.payload.error,
                checkCatMsg:action.payload.msg
            };
        case GET_CAT_SUCCESS:
            return {
                ...state,
                catStatus:true,
                catData:action.payload.mainData,
                msg:action.payload.msg
            };
        case GET_CAT_SUCCESS2:
        case FETCH_USERS_SUCCESS:
        case GET_PROJECTS_SUCCESS:
        case GET_STAFFREGDATA_SUCCESS:
            return {
                ...state,
                dataStatus:true,
                mainData:action.payload.mainData,
                msg:action.payload.msg
            };
        case GET_CAT_FAIL:
            return {
                ...state,
                catStatus:false,
                catData:null,
                msg:action.payload.msg
            };
        case GET_CAT_FAIL2:
        case FETCH_USERS_FAIL:
        case GET_PROJECTS_FAIL:
        case GET_STAFFREGDATA_FAIL: 
            return {
                ...state,
                dataStatus:false,
                mainData:null,
                msg:action.payload.msg
            };
        case GET_CAT_DETAILS_SUCCESS:
        case GET_USER_DETAILS_SUCCESS:
        case GET_PROJ_DETAILS_SUCCESS:
        case GET_ACT_SUCCESS:
            return {
                ...state,
                showDataStatus:true,
                showData:action.payload.mainData,
                msg:action.payload.msg
            };
        case GET_CAT_DETAILS_FAIL:
        case GET_USER_DETAILS_FAIL:
        case GET_PROJ_DETAILS_FAIL:
        case GET_ACT_FAIL:
            return {
                ...state,
                showDataStatus:false,
                showData:null,
                msg:action.payload.msg
            };
        case RESET_SHOWCATDETAILS_SUCCESS:
            return {
                ...state,
                showDataStatus:false,
                showData:null,
            };
        case RESET_MODALINTIALS_SUCCESS:
            return {
                ...state,
                msg:null,
                showDataStatus:null,
                showData:null,
                deleteStatus:null,
                updateStatus:null,
                updateData:null

            };
        case RESET_USERS_SUCCESS:
            return{
                ...state,
                msg:null,
                addUserStatus:null
            };
        case RESET_USER_PASS_SUCCESS:
            return{
                ...state,
                msg:null,
                changePassStatus:null
            };
        case RESET_ADDCATINTIALS_SUCCESS:
            return {
                ...state,
                addCatStatus:null,
                msg:null,
            };
        case DELETE_CAT_SUCCESS:
        case DELETE_ACT_SUCCESS:
        case APPROVE_ACT_SUCCESS:
            return {
                ...state,
                msg:action.payload.msg,
                deleteStatus:true,
            };
        case DELETE_CAT_FAIL:
        case DELETE_ACT_FAIL:
        case APPROVE_ACT_FAIL:
            return {
                ...state,
                msg:action.payload.msg,
                deleteStatus:false,
            };
        case ADDUSER_SUCCESS:
            return {
                ...state,
                addUserStatus:true,
                msg:action.payload.msg
            };
        case ADDUSER_FAIL:
            return {
                ...state,
                addUserStatus:false,
                msg:action.payload.msg
            };
        case CHANGE_USER_STATUS_SUCCESS:
        case CHANGE_PROJ_STATUS_SUCCESS:
            return {
                ...state,
                changeStatus:true,
                msg:action.payload.msg
            };
        case CHANGE_USER_STATUS_FAIL:
        case CHANGE_PROJ_STATUS_FAIL:
            return {
                ...state,
                changeStatus:false,
                msg:action.payload.msg
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePassStatus:true,
                msg:action.payload.msg
            };
        case CHANGE_PASSWORD_FAIL:
            return {
                ...state,
                changePassStatus:false,
                msg:action.payload.msg
            };
        case ADD_DATA_SUCCESS:
            return {
                ...state,
                addDataStatus:true,
                msg:action.payload.msg
            };
        case ADD_DATA_FAIL:
            return {
                ...state,
                addDataStatus:false,
                msg:action.payload.msg
            };
        case GET_TEACHER_STUDENTS_SUCCESS:
            return {
                ...state,
                getStudentsStatus:true,
                studentsData:action.payload.mainData,
                msg:action.payload.msg
            };
        case GET_TEACHER_STUDENTS_FAIL:
            return {
                ...state,
                getStudentsStatus:false,
                studentsData:null,
                msg:action.payload.msg
            };
        case ADD_MARKS_SUCCESS:
            return {
                ...state,
                addMarksStatus:true,
                msg:action.payload.msg
            };
        case ADD_MARKS_FAIL:
            return {
                ...state,
                addMarksStatus:false,
                msg:action.payload.msg
            };
        case CLASS_REPORTS_SUCCESS:
            return {
                ...state,
                reportStatus:true,
                msg:action.payload.msg,
                path:action.payload.path
            };
        case CLASS_REPORTS_FAIL:
            return {
                ...state,
                reportStatus:false,
                msg:action.payload.msg
            };
        case GETDATA_SUCCESS:
            return {
                ...state,
                getMyDataStatus:true,
                msg:action.payload.msg,
                getMyData:action.payload.mainData
            };
        case GETDATA_FAIL:
            return {
                ...state,
                getMyDataStatus:false,
                msg:action.payload.msg,
                getMyData:action.payload.mainData
            };
        case ADDMYDATA_SUCCESS:
            return {
                ...state,
                addMyDataStatus:true,
                msg:action.payload.msg,
                addMyData:action.payload.mainData
            };
        case ADDMYDATA_FAIL:
            return {
                ...state,
                addMyDataStatus:false,
                msg:action.payload.msg,
                addMyData:action.payload.mainData
            };

        default:
            return state;
    }
};

//add cat
export const addCategory = (catName,addCat,seeCat,editCat,deleteCat,addUser,seeUser,editUser,deleteUser,
addProj,seeProj,editProj,stopProj,restartProj,addAct,seeAct,editAct,approveAct,deleteAct,fullRep,actRep,budgetRep,balRep,seeRec) => dispatch => {
    
    const userD = JSON.parse(window.localStorage.getItem('userData'));
    
    const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer '+ userD.token
        }
    }

    //request body
    const body = JSON.stringify({catName,addCat,seeCat,editCat,deleteCat,addUser,seeUser,editUser,deleteUser,
        addProj,seeProj,editProj,stopProj,restartProj,addAct,seeAct,editAct,approveAct,deleteAct,fullRep,actRep,budgetRep,balRep,seeRec})
    /*sending to back end*/
    axios.post(appUrls.REACT_APP_API_URL+'/addCategory', body, config)
        .then(function(response){
            dispatch({
                type: ADDCAT_SUCCESS,
                payload: response.data
            });
        })
        .catch(err =>{
            /**call logout */
            if(err.response.status === 401){dispatch(logOut(null));}
            dispatch({
                type: ADDCAT_FAIL,
                payload: err.response.data
            })
        })
}

//update cat
export const updateCategory = (updateID,catName,addCat,seeCat,editCat,deleteCat,addUser,seeUser,editUser,deleteUser,
    addProj,seeProj,editProj,stopProj,restartProj,addAct,seeAct,editAct,approveAct,deleteAct,fullRep,actRep,budgetRep,balRep,seeRec) => dispatch => {
        
         //resset intails
    dispatch({type:MAIN_DATA_RESET});
        const userD = JSON.parse(window.localStorage.getItem('userData'));
        const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+ userD.token
            }
        }
    
        //request body
        const body = JSON.stringify({updateID,catName,addCat,seeCat,editCat,deleteCat,addUser,seeUser,editUser,deleteUser,
            addProj,seeProj,editProj,stopProj,restartProj,addAct,seeAct,editAct,approveAct,deleteAct,fullRep,actRep,budgetRep,balRep,seeRec})
        /*sending to back end*/
        axios.post(appUrls.REACT_APP_API_URL+'/updateCategory', body, config)
            .then(function(response){
                dispatch({
                    type: UPDATECAT_SUCCESS,
                    payload: response.data
                });
            })
            .catch(err =>{
                /**call logout */
                if(err.response.status === 401){dispatch(logOut(null));}
                dispatch({
                    type: UPDATECAT_FAIL,
                    payload: err.response.data
                })
            })
    }

export const checkCategory =(catName,id) => dispatch =>{
    const userD = JSON.parse(window.localStorage.getItem('userData'));
    const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer '+ userD.token
        }
    }

    const body = JSON.stringify({"catName":catName,"id":id});
    axios.post(appUrls.REACT_APP_API_URL+'/checkCategory',body, config)
        .then(function(response){
            dispatch({
                type: CAT_CHECK_SUCCESS,
                payload: response.data
            });
        })
        .catch(err => {
            /**call logout */
            if(err.response.status === 401){dispatch(logOut(null));}
            dispatch({
                type: CAT_CHECK_FAIL,
                payload: err.response.data
            })
        })

}
/**get categories */
export const getCatData =() => dispatch =>{
    //resset intails
    dispatch({type:CAT_DATA_RESET});

    const userD = JSON.parse(window.localStorage.getItem('userData'));
    const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer '+ userD.token
        }
    }

    axios.post(appUrls.REACT_APP_API_URL+'/getCategories',null, config)
        .then(function(response){
            dispatch({
                type: GET_CAT_SUCCESS,
                payload:response.data
            });
        })
        .catch(err => {
            /**call logout */
            if(err.response.status === 401){dispatch(logOut(null));}
            dispatch({
                type: GET_CAT_FAIL,
                payload: err.response.data
            })
        })

}
/**get main data for all */
export const getMainData =(requestFrom) => dispatch =>{
    //resset intails
    dispatch({type:MAIN_DATA_RESET});

    const userD = JSON.parse(window.localStorage.getItem('userData'));
    const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer '+ userD.token
        }
    }

    var exactPath,typeSuccess,typeFail;
    if(requestFrom ==="CAT"){
        exactPath = 'getCategories';
        typeSuccess = GET_CAT_SUCCESS2;
        typeFail = GET_CAT_FAIL2
    }else if(requestFrom ==="USER"){
        exactPath = 'getUsers';
        typeSuccess = FETCH_USERS_SUCCESS;
        typeFail = FETCH_USERS_FAIL
    }else if(requestFrom ==="PROJ"){
        exactPath = 'getProjects';
        typeSuccess = GET_PROJECTS_SUCCESS;
        typeFail = GET_PROJECTS_FAIL
    }else if(requestFrom ==="STAFFREGDATA"){
        exactPath = 'getStaffRegData';
        typeSuccess = GET_STAFFREGDATA_SUCCESS;
        typeFail = GET_STAFFREGDATA_FAIL
    }else if(requestFrom ==="CLIENTS"){
        exactPath = 'getClients';
        typeSuccess = FETCH_USERS_SUCCESS;
        typeFail = FETCH_USERS_FAIL
    }else if(requestFrom ==="BLACKLISTED_CLIENTS"){
        exactPath = 'getBlackListedClients';
        typeSuccess = FETCH_USERS_SUCCESS;
        typeFail = FETCH_USERS_FAIL
    
    }else if(requestFrom ==="GENERAL_PAYDATA"){
    
        exactPath = 'getGeneralPaymentData';
        typeSuccess = FETCH_USERS_SUCCESS;
        typeFail = FETCH_USERS_FAIL
    }else if (requestFrom ==="EXPENSECAT"){
        exactPath = 'getExpenseCat';
        typeSuccess = GET_STAFFREGDATA_SUCCESS;
        typeFail = GET_STAFFREGDATA_FAIL
    }else if (requestFrom ==="ACCOUNTS"){
        exactPath = 'getAccounts';
        typeSuccess = GET_STAFFREGDATA_SUCCESS;
        typeFail = GET_STAFFREGDATA_FAIL
    }else if (requestFrom ==="TARGETCAT"){
        exactPath = 'getTargetCat';
        typeSuccess = GET_STAFFREGDATA_SUCCESS;
        typeFail = GET_STAFFREGDATA_FAIL
    }else if (requestFrom ==="GETTRANSFER"){
        exactPath = 'getTransfer';
        typeSuccess = GET_STAFFREGDATA_SUCCESS;
        typeFail = GET_STAFFREGDATA_FAIL
    }else if (requestFrom ==="GETCAPITAL"){
        exactPath = 'getCapital';
        typeSuccess = GET_STAFFREGDATA_SUCCESS;
        typeFail = GET_STAFFREGDATA_FAIL
    }else if (requestFrom ==="GENERAL_REPORT"){
        exactPath = 'getGeneralData';
        typeSuccess = GET_STAFFREGDATA_SUCCESS;
        typeFail = GET_STAFFREGDATA_FAIL
    }else if (requestFrom ==="AGENT_DATA"){
        exactPath = 'getAgentListData';
        typeSuccess = GET_STAFFREGDATA_SUCCESS;
        typeFail = GET_STAFFREGDATA_FAIL
    }else if(requestFrom ==="MISSED_DAYS_PAYDATA"){

        exactPath = 'getMissedDaysData';
        typeSuccess = FETCH_USERS_SUCCESS;
        typeFail = FETCH_USERS_FAIL
    }else if (requestFrom ==="TICKET_CAT"){
        exactPath = 'getTicketsCategory';
        typeSuccess = GET_STAFFREGDATA_SUCCESS;
        typeFail = GET_STAFFREGDATA_FAIL
    }else if (requestFrom ==="GETBRADATA"){
        exactPath = 'getStaffBra';
        typeSuccess = GET_STAFFREGDATA_SUCCESS;
        typeFail = GET_STAFFREGDATA_FAIL
    }else if (requestFrom ==="TICKETS"){
        exactPath = 'getTickets';
        typeSuccess = GET_STAFFREGDATA_SUCCESS;
        typeFail = GET_STAFFREGDATA_FAIL
    }else if(requestFrom ==="EXPIRED_LOAND"){
        exactPath = 'getExpiredLoansData';
        typeSuccess = FETCH_USERS_SUCCESS;
        typeFail = FETCH_USERS_FAIL
    }else if(requestFrom ==="STAFFREGDATA"){
        exactPath = 'getSettingsData';
        typeSuccess = GET_STAFFREGDATA_SUCCESS;
        typeFail = GET_STAFFREGDATA_FAIL
    }else if(requestFrom ==="BRANCHES"){
        exactPath = 'getBranches';
        typeSuccess = GET_STAFFREGDATA_SUCCESS;
        typeFail = GET_STAFFREGDATA_FAIL
    }else if (requestFrom ==="PROCESSFEE"){
        exactPath = 'getProcessFee';
        typeSuccess = GET_STAFFREGDATA_SUCCESS;
        typeFail = GET_STAFFREGDATA_FAIL
    }else if (requestFrom ==="LOANTYPES"){
        exactPath = 'getLoanType';
        typeSuccess = GET_STAFFREGDATA_SUCCESS;
        typeFail = GET_STAFFREGDATA_FAIL
    }else if (requestFrom ==="ASSETS"){
        exactPath = 'getAssets';
        typeSuccess = GET_STAFFREGDATA_SUCCESS;
        typeFail = GET_STAFFREGDATA_FAIL
    }else if (requestFrom ==="ACTIVELOAN_DATA"){
        exactPath = 'activeData';
        typeSuccess = GET_STAFFREGDATA_SUCCESS;
        typeFail = GET_STAFFREGDATA_FAIL
    }else if (requestFrom ==="ALLPaymentData"){
        exactPath = 'getPayData';
        typeSuccess = GET_STAFFREGDATA_SUCCESS;
        typeFail = GET_STAFFREGDATA_FAIL
    }else if (requestFrom ==="repoData"){
        exactPath = 'getRepoData';
        typeSuccess = GET_STAFFREGDATA_SUCCESS;
        typeFail = GET_STAFFREGDATA_FAIL
    }



    


    

    axios.post(appUrls.REACT_APP_API_URL+'/'+exactPath,null, config)
        .then(function(response){
            dispatch({
                type: typeSuccess,
                payload:response.data
            });
        })
        .catch(err => {
            /**call logout */
            if(err.response.status === 401){dispatch(logOut(null));}
            dispatch({
                type: typeFail,
                payload: err.response.data
            })
        })

}
/**getSelected for all */
export const getSelectedID_Data =(id,requestFrom) => dispatch =>{

    dispatch({type:MAIN_DATA_RESET});
   
    const userD = JSON.parse(window.localStorage.getItem('userData'));
    const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer '+ userD.token
        }
    }
  
    const body = JSON.stringify({"showID":id});

    var exactPath,typeSuccess,typeFail;
    if(requestFrom ==="CAT"){
        exactPath = 'showCategory';
        typeSuccess = GET_CAT_DETAILS_SUCCESS;
        typeFail = GET_CAT_DETAILS_FAIL
    }else if(requestFrom ==="USER"){
        exactPath = 'showUser';
        typeSuccess = GET_USER_DETAILS_SUCCESS;
        typeFail = GET_USER_DETAILS_FAIL
    }else if(requestFrom ==="PROJ"){
        exactPath = 'showProject';
        typeSuccess = GET_PROJ_DETAILS_SUCCESS;
        typeFail = GET_PROJ_DETAILS_FAIL
    }else if(requestFrom ==="ACT"){
        exactPath = 'showActivities';
        typeSuccess = GET_ACT_SUCCESS;
        typeFail = GET_ACT_FAIL
    }else if(requestFrom ==="CLIENT"){
        exactPath = 'clientDetails';
        typeSuccess = GET_USER_DETAILS_SUCCESS;
        typeFail = GET_USER_DETAILS_FAIL
    }else if(requestFrom ==="LOANREQ"){
        exactPath = 'getLoanRequestDetails';
        typeSuccess = GET_USER_DETAILS_SUCCESS;
        typeFail = GET_USER_DETAILS_FAIL
    }

    
    axios.post(appUrls.REACT_APP_API_URL+'/'+exactPath,body, config)
    .then(function(response){
        dispatch({
            type: typeSuccess,
            payload:response.data
        });
    })
    .catch(err =>{
        /**call logout*/
        if(err.response.status === 401){dispatch(logOut(null));}
        dispatch({
            type: typeFail,
            payload: err.response.data
        })
    })
}
/**deleteCategory */
export const deleteData =(id,requestFrom) => dispatch =>{
    //resset intails
    dispatch({type:MAIN_DATA_RESET});

    const userD = JSON.parse(window.localStorage.getItem('userData'));
    const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer '+ userD.token
        }
    }

    var exactPath,typeSuccess,typeFail;
    if(requestFrom ==="CAT"){
        exactPath = 'deleteCategory';
        typeSuccess = DELETE_CAT_SUCCESS;
        typeFail = DELETE_CAT_FAIL
    }else if(requestFrom ==="ACT"){
        console.log("tyuiop");
        exactPath = 'deleteActivity';
        typeSuccess = DELETE_ACT_SUCCESS;
        typeFail = DELETE_ACT_FAIL
    }else if(requestFrom ==="ACT_APPROVE"){
        console.log("tyuiop");
        exactPath = 'approveActivity';
        typeSuccess = APPROVE_ACT_SUCCESS;
        typeFail = APPROVE_ACT_FAIL
    }

    const body = JSON.stringify({"id":id});

    axios.post(appUrls.REACT_APP_API_URL+'/'+exactPath,body, config)
        .then(function(response){
            dispatch({
                type:typeSuccess ,
                payload: response.data
            });
        })
        .catch(err => {
            /**call logout */
            if(err.response.status === 401){dispatch(logOut(null));}
            dispatch({
                type: typeFail,
                payload: err.response.data
            })
        })

}
/**resetShowCatDetails */
export const resetShowCatDetails =() => dispatch =>{
    dispatch({ type: RESET_SHOWCATDETAILS_SUCCESS });
}

/**resetShowCatDetails */
export const resetModalIntials =() => dispatch =>{
    dispatch({ type: RESET_MODALINTIALS_SUCCESS });
}

/**restAddCatIntials */
export const restAddCatIntials =() => dispatch =>{
    dispatch({ type: RESET_ADDCATINTIALS_SUCCESS });
}

/**restAddCatIntials */
export const restUser =() => dispatch =>{
    dispatch({ type: RESET_USERS_SUCCESS });
}

/**addUser */
export const addUser = (formData,type) => dispatch => {   
    
    dispatch({ type: RESET_USERS_SUCCESS });
    
    const userD = JSON.parse(window.localStorage.getItem('userData'));
    const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer '+ userD.token
        }
    }
    var endPoint = ""
    if(type ==="SYSTEM_USER"){
        endPoint = "reg"
    }else if (type ==="SYSTEM_CLIENT"){
        endPoint = "regClient"
    }else if (type ==="STUDENT"){
        endPoint = "regStudent"
    }else if(type ==="ASSIGN_TEACHER"){
        endPoint = "assignTeacher"
    }else if(type ==="STUDENT_UPDATE"){
        endPoint = "updateStudent"
    }else if(type ==="PAPER_UPDATE"){
        endPoint = "updatePaper"
    }else if(type ==="UNASIGN_TEACHER"){
        endPoint = "unassignTeacher"
    }else if(type ==="REPORT_SETTINGS_UPDATE"){
        endPoint = "updateReportSettings"
    }else if(type ==="ADD_UNKNOWN"){
        endPoint = "addUnknownPayments"
    }else if(type ==="STAFF_REPORT"){
        endPoint = "getStaffReportData"
    }else if(type ==="UPDATE_SYSTEM_SETTINGS"){
        endPoint = "updateSystemSettings"
    }else if(type ==="UPDATE_RENEWAL_SETTINGS"){
        endPoint = "updateLoanRenewalSettings"
    }else if(type ==="ADD_EXPENSE_CAT"){
        endPoint = "addExpenseCat"
    }else if(type ==="UPDATE_EXPENSECAT"){
        endPoint = "changeExpenseCatStatus"
    }else if(type ==="ADD_EXPENSE"){
        endPoint = "addExpense"
    }else if(type ==="UPDATE_EXPENSE"){
        endPoint = "updateExpense"
    }else if(type ==="ADD_ACCOUNT"){
        endPoint = "addAccount"
    }else if(type ==="UPDATE_ACCOUNT"){
        endPoint = "updateAccount"
    }else if(type ==="UPDATE_USER"){
        endPoint = "updateUser"
    }else if(type ==="UPDATE_USER_STATUS"){
        endPoint = "updateUserStatus"
    }else if(type ==="ADD_TARGET_CAT"){
        endPoint = "addTargetCat"
    }else if(type ==="UPDATE_TARGETCAT"){
        endPoint = "changeTargetCatStatus"
    }else if(type ==="TransferLoan"){
        endPoint = "transferLoanne"
    }else if(type ==="ADD_CAPITAL"){
        endPoint = "addCapital"
    }else if(type ==="UPDATE_CAPITAL"){
        endPoint ="updateCapital"
    }else if(type ==="ADD_TICKETCAT"){
        endPoint ="addticketCat"
    }else if(type ==="UPDATE_TICKETCAT"){
        endPoint = "updateticketCat"
    }else if(type ==="ADD_TICKET"){
        endPoint = "addTicket"
    }else if(type ==="UPDATE_TICKET"){
        endPoint = "updateTicket"
    }else if(type ==="ADD_BRANCH"){
        endPoint = "addBranch"
    }else if(type ==="UPDATE_BRANCH"){
        endPoint = "updateBranch"
    }else if(type ==="ADD_PROCESSFEE"){
        endPoint = "addProcessFee"
    }else if(type ==="UPDATE_PROCESSFEE"){
        endPoint = "updateProcessFee"
    }else if(type ==="ADD_LOANTYPE"){
        endPoint = "addLoanType"
    }else if(type ==="UPDATE_LOANTYPE"){
        endPoint = "updateLoanType"
    }else if(type ==="ADD_ASSET"){
        endPoint = "addAsset"
    }else if(type ==="UPDATE_ASSETS"){
        endPoint = "updateAsset"
    }else if(type ==="ADD_PAYMENT"){
        endPoint = "addAllPayment"
    }
    else if(type ==="ADD_PAYMENTFINE"){
        endPoint = "addPaymentFine"
    }
    else if(type ==="ADD_ALLLOAN"){
        endPoint = "addAllLoan"
    }else if(type ==="GET_PAYMENT"){
        endPoint = "addAllPayment"
    }

   


    

    
    
    //request body
    const body = formData
    /*sending to back end*/
    axios.post(appUrls.REACT_APP_API_URL+'/'+endPoint, body, config)
        .then(function(response){
            dispatch({
                type: ADDUSER_SUCCESS,
                payload: response.data
            });
        })
        .catch(err =>{
            /**call logout */
            if(err.response.status === 401){dispatch(logOut(null));}
            dispatch({
                type: ADDUSER_FAIL,
                payload: err.response.data
            })
        })
}

export const updateUser = (userID,name,username,phone,accountType) => dispatch => {
         //resset intails
    dispatch({type:MAIN_DATA_RESET});
        const userD = JSON.parse(window.localStorage.getItem('userData'));
        const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : 'Bearer '+ userD.token
            }
        }
    
        //request body
        const body = JSON.stringify({userID,name,username,phone,accountType})
        /*sending to back end*/
        axios.post(appUrls.REACT_APP_API_URL+'/updateUser', body, config)
            .then(function(response){
                dispatch({
                    type: UPDATEUSER_SUCCESS,
                    payload: response.data
                });
            })
            .catch(err =>{
                /**call logout */
                if(err.response.status === 401){dispatch(logOut(null));}
                dispatch({
                    type: UPDATEUSER_FAIL,
                    payload: err.response.data
                })
            })
    }
export const changeStatus =(id,status,requestFrom) => dispatch =>{
    //resset intails
    dispatch({type:MAIN_DATA_RESET});

    const userD = JSON.parse(window.localStorage.getItem('userData'));
    const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer '+ userD.token
        }
    }

    var exactPath,typeSuccess,typeFail;
    if(requestFrom ==="USER"){
        exactPath = 'changeUserStatus';
        typeSuccess = CHANGE_USER_STATUS_SUCCESS;
        typeFail = CHANGE_USER_STATUS_FAIL
      
    }else if(requestFrom ==="PROJ"){
        exactPath = 'changeProjStatus';
        typeSuccess = CHANGE_PROJ_STATUS_SUCCESS;
        typeFail = CHANGE_PROJ_STATUS_FAIL
    }
    const body = JSON.stringify({"id":id,"status":status});
    axios.post(appUrls.REACT_APP_API_URL+'/'+exactPath,body, config)
        .then(function(response){
            dispatch({
                type: typeSuccess,
                payload:response.data
            });
        })
        .catch(err => {
            /**call logout */
            if(err.response.status === 401){dispatch(logOut(null));}
            dispatch({
                type: typeFail,
                payload: err.response.data
            })
        })

}
/**changePassword */
export const changePassword = (id,password,password2) => dispatch => {
    //resset intails
    dispatch({type:RESET_USER_PASS_SUCCESS});
    
   const userD = JSON.parse(window.localStorage.getItem('userData'));
   const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
       headers: {
           'Content-Type': 'application/json',
           'Authorization' : 'Bearer '+ userD.token
       }
   }

   //request body
   const body = JSON.stringify({id,password,password2})
   /*sending to back end*/
   axios.post(appUrls.REACT_APP_API_URL+'/changePassword', body, config)
       .then(function(response){
           dispatch({
               type: CHANGE_PASSWORD_SUCCESS,
               payload: response.data
           });
       })
       .catch(err =>{
           /**call logout */
           if(err.response.status === 401){dispatch(logOut(null));}
           dispatch({
               type: CHANGE_PASSWORD_FAIL,
               payload: err.response.data
           })
       })
}

/** */
export const addDataFile =(formData,requestFrom) => dispatch =>{

    //resset intails
    dispatch({type:MAIN_DATA_RESET});

    const userD = JSON.parse(window.localStorage.getItem('userData'));
    const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
        headers: {
            'Content-Type': ['multipart/form-data','application/json'],
            'Authorization' : 'Bearer '+ userD.token
        }
    }

    var exactPath,typeSuccess,typeFail;
    if(requestFrom ==="SUBJECT"){
        exactPath = 'addSubject';
        typeSuccess = ADD_DATA_SUCCESS;
        typeFail = ADD_DATA_FAIL
    }
    if(requestFrom ==="CHAPTER"){
        exactPath = 'addChapters';
        typeSuccess = ADD_DATA_SUCCESS;
        typeFail = ADD_DATA_FAIL
    }
    
    axios.post(appUrls.REACT_APP_API_URL+'/'+exactPath,formData, config)
        .then(function(response){
            dispatch({
                type: typeSuccess,
                payload:response.data
            });
        })
        .catch(err => {
            /**call logout */
            if(err.response.status === 401){dispatch(logOut(null));}
            dispatch({
                type: typeFail,
                payload: err.response.data
            })
        })
}


export const addLoanData = (data,action) => dispatch => {
    //resset intails
    dispatch({type:MAIN_DATA_RESET});
   const userD = JSON.parse(window.localStorage.getItem('userData'));
   const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
       headers: {
           'Content-Type': 'application/json',
           'Authorization' : 'Bearer '+ userD.token
       }
   }

    var path=""
    if(action ==="ADDLOAN"){
       path ="addLoan"
    }
   
    if(action ==="ADDFINE"){
        path ="addFine"
    }

    if(action ==="PAYLOAN"){
        path ="payLoan"
    }

    if(action ==="EDITLOANPAY"){
        path ="editLoanPay"
    }

    if(action ==="TRANSFERLOANPAY"){
        path ="transferLoanPay"
    }

    if(action ==="STAFF_REPORT"){
        path ="getStaffReportData"
    }

    if(action ==="STAFF_GENERAL_REPORT"){
        path ="getStaffGeneralReportData"
    }



    if(action ==="RENEWLOAN"){
        path ="approveLoanRenewal"
    }

    if(action ==="GENERAL_REPORT"){
        path ="generalReport"
    }
    if(action ==="DAILY_REPORT"){
        path ="getDailyReportData"
    }

    if(action ==="UPDATECLIENT"){
        path ="updateClient"
    }

    if(action ==="CLIENT_ACTIVATE"){
        path ="activateClient"
    }

    if(action ==="BLACKLIST"){
        path ="blacklistClient"
    }

    if(action ==="UNBLACKLIST"){
        path ="unblacklistClient"
    }
    if(action ==="MARKDEFAULTER"){
        path ="makeDefaulter"
    }
    if(action ==="DELETELOAN"){
        path ="deleteLoan"
    }

    if(action ==="BIZ_GRAPH"){
        path ="getBizGraphData"
    }
    if(action ==="STAFF_GRAPH"){
        path ="getsStaffGraphData"
    }
    if(action ==="GENERATE_RENWALREQUEST"){
        path ="generateManualRenewalRequest"
    }

    if(action ==="STAFF_PERFORMANCE_REPORT"){
        path ="getStaffPerformanceReportData"
    }

    if(action ==="SUBMITAPPROVAL"){
        path ="addLoanRequestApproval"
    }

    if(action ==="REOPENLOAN"){
        path ="reopenLoan"
    }
    if(action ==="REPORTTRACKER"){
        path ="reportTracker"
    }

    if(action ==="FORGIVEFINE"){
        path ="forgiveFine"
    }

    if(action ==="SUBMIT_FEILDREPO"){
        path ="submitFeildReport"
    }
    if(action ==="APPROVE_REQ"){
        path ="approveRejectLoanRequest"
    }

    if(action ==="UPDATE_REQ"){
        path ="updateLoanRequest"
    }

    if(action ==="GIVE_APPR_LOAN"){
        path ="giveApprovedLoan"
    }
    if(action ==="PRINTPAYMENT"){
        path ="printPayment"
    }
    
    if(action ==="EXPENSES_REPORT"){
        path ="getExpensesReportData"
    }

    


    
    
    
    



   const body = data
   axios.post(appUrls.REACT_APP_API_URL+'/'+path,body,config)

    .then(function(response){
        dispatch({
            type: UPDATEUSER_SUCCESS,
            payload: response.data
        });
    })
    .catch(err =>{
        /**call logout */
        if(err.response.status === 401){dispatch(logOut(null));}
        dispatch({
            type: UPDATEUSER_FAIL,
            payload: err.response.data
        })
    })
}


export const getData = (data,action) => dispatch => {
    //resset intails
   dispatch({type:GETMYDATARESET});
   const userD = JSON.parse(window.localStorage.getItem('userData'));
   const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
        headers: {
           'Content-Type': 'application/json',
           'Authorization' : 'Bearer '+ userD.token
        }
   }

    var path=""
    if(action ==="AGENTLIST"){
       path ="getAgentList"
    }
    if(action ==="PAYMENTSDATA"){
        path ="getPaymentData"
    }
    if(action ==="GETMYBRANCH"){
        path ="getMyBranchData"
    }

    if(action ==="GET_UNKWON_DATA"){
        path = 'getGetUnknownData';
    }
    if(action ==="GIVEN_OUTDATA"){
        path ="getLoansGivenData"
    }
    if(action ==="CLOSED_LOANSDATA"){
        path ="getClsedLoansData"
    }
    if(action ==="GETSTAFFLIST"){
        path = "getStaffClientList"
    }
    if(action ==="GETFINES"){
        path = "getFinesData"
    }
    if(action ==="LOAN_REQUESTS"){
        path = "getLoanRequestsData"
    }
    if(action ==="GETFINES_APPROVAL"){
        path = "getFinesApprovalData"
    }
    if(action ==="LOANS_APPROVAL"){
        path = "getLoansApprovalData"
    }
    if(action ==="GETMONTH_APPROVAL"){
        path = "getMonthApprovalData"
    }

    if(action ==="GETWELFARE_APPROVAL"){
        path = "getWelfareApprovalData"
    }

    if(action ==="GETLOANPAY_APPROVAL"){
        path = "getLoanPayApprovalData"
    }

    if(action ==="GIVEN_SEARCHCLUBDATA"){
        path = "getClubSearchedData"
    }

    if(action ==="GET_SEARCHMEMBERDATA"){
        path = "getMemberSearchedData"
    }
    if(action ==="ALLPAYMENTSDATA"){
        path ="getAllPaymentData"
    }
    if(action ==="ALLFINESDATA"){
        path ="getAllFineData"
    }
    if(action ==="ALLLOANSDATA"){
        path ="getAllLoansData"
    }

    if(action ==="GET_OPENREPORT"){
        path ="getOpenReport"
    }

    if(action ==="GET_CURRENTREPORT"){
        path ="getCurrentReport"
    }

    if(action ==="GET_MEMBERREPORT"){
        path ="getMemberReport"
    }
    if(action ==="GET_MEMBERREPORT_LOAN"){
        path ="getMemberReportLoan"
    }





  


    

    

    


    
    
    
    
   
   const body = data
   axios.post(appUrls.REACT_APP_API_URL+'/'+path,body,config)
    
    .then(function(response){
        dispatch({
            type: GETDATA_SUCCESS,
            payload: response.data
        });
    })
    .catch(err =>{
        console.log(err)
        /**call logout */
        if(err.response.status === 401){dispatch(logOut(null));}
        dispatch({
            type: GETDATA_FAIL,
            payload: err.response.data
        })
    })
}

export const addMyData = (data,action) => dispatch => {
    //resset intails
   dispatch({type:ADDMYDATARESET});
   const userD = JSON.parse(window.localStorage.getItem('userData'));
   const appUrls = JSON.parse(window.localStorage.getItem('appUrls'));
    const config = {
       headers: {
           'Content-Type': 'application/json',
           'Authorization' : 'Bearer '+ userD.token
       }
   }

    var path=""
    if(action ==="ADDLOANPAY"){
       path ="addUnapprovedPayment"
    }
    if(action ==="APPROVEPAYMENT"){
        path ="approvedPayment"
    }
    if(action ==="EDITUPAYMENT"){
        path ="editUnapprovedPayment"
    }
    if(action ==="DELETEUPAYMENT"){
        path ="deleteUnapprovedPayment"
    }
    if(action ==="APPROVE_FINE"){
        path ="approveFine"
    }
    if(action ==="APPROVE_LOAN"){
        path ="approveLoan"
    }
    if(action ==="APPROVE_MONTH"){
        path ="approveMonthPay"
    }
    if(action ==="APPROVE_LOANPAY"){
        path ="approveLoanPay"
    }
    if(action ==="UPDATE_SHARESCAPITAL"){
        path ="updateShareCapital"
    }

    


    


   const body = data
   axios.post(appUrls.REACT_APP_API_URL+'/'+path,body,config)

    .then(function(response){
        dispatch({
            type: ADDMYDATA_SUCCESS,
            payload: response.data
        });
    })
    .catch(err =>{
        /**call logout */
        if(err.response.status === 401){dispatch(logOut(null));}
        dispatch({
            type: ADDMYDATA_FAIL,
            payload: err.response.data
        })
    })
}




