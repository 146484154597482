import React, { Component } from 'react';
import {connect } from 'react-redux';
import {getMainData,getData} from "../../Redux/ducks/mainReducer";
import {Alert,Label,Row,Col,FormGroup,Input, Button,Spinner,Table} from 'reactstrap'

import ReactJsAlert from "reactjs-alert"
import jsPDF from 'jspdf';
import 'jspdf-autotable';
export class CalculateShareCapital extends Component {
    state={
        isSaving:false,
        mainData:null,
        msg:null,
        addCatMsg:null,
        btnDisabled:false,
        errorMsg:null,
        successMsg:null,
    
        alertStatus :false, alertType :"error", alertTitle:null,
        qty:"",total:"",cost:"",details:"",staff:"",cat:"",name:"",myDate:"",
        reportData:null,getReport:false,

        balances:0,bank:0,box:0,sys:0,goodwill:0,other:0,investment:0,totalMembers:0

    }

    componentDidMount(){
        const data = new FormData();
        this.props.getData(data,"GET_CURRENTREPORT")
    }

    componentDidUpdate(prevProps){
        const{mainData,msg,mainDataStatus,addUserStatus,getReportData,addMyDataStatus,addMyData} = this.props;
        
        if(mainDataStatus !== prevProps.mainDataStatus){
            if(mainDataStatus===true){
                this.setState({isSaving:false,mainData:mainData
                })
            }
        } 

        if(addUserStatus !== prevProps.addUserStatus){
            if(addUserStatus===true){

                
                let topenLoan = 0
                let topenContribution = 0
                let topenFines = 0
                let topenWelfare = 0
                let topenIntrest = 0
                let totalMembers =0
                if(getReportData !==null){
                    getReportData.map((values,i) =>{
                        totalMembers = totalMembers+1
                        if(parseInt(i)===0){
                            this.setState({
                                bank:values.shareData.bank,
                                box:values.shareData.box,
                                sys:values.shareData.system,
                                goodwill:values.shareData.goodwill,
                                other:values.shareData.others,
                                investment:values.shareData.investment,
                               
                            })
                        }
                        topenLoan = topenLoan+parseInt(values.openLoan)
                        topenContribution = topenContribution+parseInt(values.openContribution)
                        topenFines = topenFines+parseInt(values.openFines)
                        topenWelfare = topenWelfare+parseInt(values.openWelfare)
                        topenIntrest = topenIntrest+parseInt(values.openIntrest)
                    
                    })
        
                }

                let totalBal = parseInt(topenLoan)+
                parseInt(topenIntrest)+parseInt(topenContribution)+
                parseInt(topenFines)+parseInt(topenWelfare)
                
                this.setState({isSaving:false,successMsg:msg,btnDisabled:false,reportData:getReportData,getReport:true,
                    balances:totalBal,totalMembers:totalMembers
                })



            }else if(addUserStatus===false){
                this.setState({isSaving:false,erroMsg:msg,btnDisabled:false,
                    alertStatus :true, alertType :"error", alertTitle:msg,getReport:false})
            }
        } 

      
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    generatePDF = () => {
        // Create a new jsPDF instance with landscape orientation and A4 size
        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: 'a4',
        });
    
        // Get page width for centering header text
        const pageWidth = doc.internal.pageSize.getWidth();
        doc.setFontSize(12);
    
        // Define table headers for the first table
        const tableHeaders = [
            ['Item.', 'Amount']
        ];
    
        // Generate the first table rows
        const rows = this.generateDataRows("DETAILS");
    
        // Define column widths: set to null to let autoTable take care of it
        const columnWidths = [null, null]; // Use null for auto-distribution
    
        const topMargin = 25; // Adjust top margin for the first table
    
        // Table title for the first table
        const tableTitle1 = "CURRENT SHARE CAPITAL REPORT";
    
        // Set font size and add title above the first table
        doc.setFontSize(12);
        const textWidth1 = doc.getTextWidth(tableTitle1);
        const x1 = (pageWidth - textWidth1) / 2;
        const y1 = topMargin - 5; // Position the title just above the first table
        doc.text(tableTitle1, x1, y1);
    
        // Generate the first table in the PDF
        doc.autoTable({
            head: tableHeaders,
            body: rows,
            startY: topMargin + 10, // Adjusted to ensure spacing after title
            columnStyles: this.getColumnStyles(columnWidths),
            styles: this.getTableStyles(),
            headStyles: this.getHeaderStyles(),
            pageBreak: 'auto',
            margin: { top: 30, bottom: 10 },
            tableWidth: 'auto',  // Let autoTable handle the table width
        });
    
        // Calculate the Y position for the second table
        const secondTableStartY = doc.lastAutoTable.finalY + 10; // Position it after the first table
    
        // Table title for the second table
        const tableTitle2 = "Share Capital";
        const textWidth2 = doc.getTextWidth(tableTitle2);
        doc.text(tableTitle2, x1, secondTableStartY); // Use the same X position but a new Y position
    
        // Define table headers for the second table
        const tableHeaders2 = [
            ['TOTAL AMOUNT', 'TOTAL MEMBERS', 'CURRENT SHARE CAPITAL']
        ];
    
        // Generate the second table rows
        const rows2 = this.generateDataRows("SHARES");
    
        // Generate the second table in the PDF
        doc.autoTable({
            head: tableHeaders2,
            body: rows2,
            startY: secondTableStartY + 10, // Adjusted to ensure spacing after title
            columnStyles: this.getColumnStyles(columnWidths),
            styles: this.getTableStyles(),
            headStyles: this.getHeaderStyles(),
            pageBreak: 'auto',
            margin: { top: 30, bottom: 10 },
            tableWidth: 'auto',  // Let autoTable handle the table width
        });
    
        // Save the generated PDF
        doc.save("current_share_capital_Report.pdf");
    };
    
    // Method to generate rows from loan data
    generateDataRows = (action) => {
        const { bank, box, sys, goodwill, other, investment, balances, totalMembers } = this.state;
    
        let myData = [];
    
        if (action === "DETAILS") {
            myData = [
                ["Members Current Balances", parseInt(balances).toLocaleString()],
                ["Cash At Bank", parseInt(bank).toLocaleString()],
                ["Investment", parseInt(investment).toLocaleString()],
                ["Deposit Box", parseInt(box).toLocaleString()],
                ["System Current Value", parseInt(sys).toLocaleString()],
                ["Goodwill", parseInt(goodwill).toLocaleString()],
                ["Other Incomes", parseInt(other).toLocaleString()]
            ];
        }
    
        if (action === "SHARES") {
            const totalAmount = parseInt(bank) + parseInt(box) + parseInt(sys) + parseInt(goodwill) + parseInt(other) + parseInt(investment) + parseInt(balances);
            myData = [
                [totalAmount.toLocaleString(), parseInt(totalMembers).toLocaleString(), (totalAmount / parseInt(totalMembers)).toLocaleString()]
            ];
        }
    
        return myData;
    };
    
    
    // Method to get column styles
    getColumnStyles = (widths) => {
        return widths.reduce((styles, width, index) => {
            styles[index] = { cellWidth: width };
            return styles;
        }, {});
    };
    
    // Method to get table styles
    getTableStyles = () => ({
        fontSize: 9,
        cellPadding: 2,
        overflow: 'linebreak',
        halign: 'center',
        fontStyle: 'normal',
    });
    
    // Method to get header styles
    getHeaderStyles = () => ({
        fillColor: [20, 224, 239],  // Header background color
        fontStyle: 'bold',
        textColor: [3, 4, 2],        // Header text color (black)
    });




    render() {
        const {bank,box,sys,goodwill,other,investment,balances,totalMembers}=this.state
     
        return (
            <div>
                <div>
                    <Row className="mainWrraper">
                        <div className="block-header">
                        <h4>SEE CURRENT SHARE CAPITAL REPORT </h4>
                        </div>
                    <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>
                    {
                        this.state.getReport ===true? 
                        <Col xs="12" >
                            <Button style={{ marginTop: 10 }}  onClick={this.generatePDF} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-success">Download Report </Button>
                        </Col>:null
                    }
                    <Col xs="12" >
                        {this.state.isLoading?
                            <div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}</div>
                        :
                        <>
                        <div className='table-container '>            
                            <h6>Details</h6>
                            <Table striped responsive>
                                <thead>
                                    <tr>
                                        <th>Item</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Members Current Balances</td>
                                        <td>{parseInt(this.state.balances).toLocaleString()}</td>
                                    </tr>

                                    <tr>
                                        <td>Cash At Bank</td>
                                        <td>{parseInt(this.state.bank).toLocaleString()}</td>
                                    </tr>

                                    <tr>
                                        <td>Investment</td>
                                        <td>{parseInt(this.state.investment).toLocaleString()}</td>
                                    </tr>

                                    <tr>
                                        <td>Deposit Box</td>
                                        <td>{parseInt(this.state.box).toLocaleString()}</td>
                                    </tr>

                                    <tr>
                                        <td>System Current Value</td>
                                        <td>{parseInt(this.state.sys).toLocaleString()}</td>
                                    </tr>

                                    <tr>
                                        <td>Goodwill</td>
                                        <td>{parseInt(this.state.goodwill).toLocaleString()}</td>
                                    </tr>

                                    <tr>
                                        <td>Other Incomes</td>
                                        <td>{parseInt(this.state.other).toLocaleString()}</td>
                                    </tr>
                                </tbody>
                            </Table>    

                            <br/><br/> 
                            <h6>Share Capital</h6>
                            <Table striped responsive>
                                <thead>
                                    <tr>
                                        <th>TOTAL AMOUNT</th>
                                        <th>TOTAL MEMBERS</th>
                                        <th>CURRENT SHARE CAPITAL</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {(parseInt(bank)+parseInt(box)+parseInt(sys)+parseInt(goodwill)+parseInt(other)+parseInt(investment)+parseInt(balances)).toLocaleString()}
                                        </td>
                                        <td>{parseInt(totalMembers).toLocaleString()}</td>
                                        <td>
                                            {((parseInt(bank)+parseInt(box)+parseInt(sys)+parseInt(goodwill)+parseInt(other)+parseInt(investment)+parseInt(balances))/parseInt(totalMembers)).toLocaleString()}
                                        </td>
                                    </tr>

                                   
                                </tbody>
                            </Table>  
                        </div>
                           
                        </>
                        }
                            
                    
                    </Col>
                    
                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    mainDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    mainData : state.mainReducer.mainData,
    getReportData : state.mainReducer.getMyData,
    addUserStatus:state.mainReducer.getMyDataStatus,
});
export default connect(
    mapStateToProps, {getMainData,getData}
)(CalculateShareCapital)
