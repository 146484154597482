import React, { Component } from 'react';
import {connect } from 'react-redux';
import {getMainData,getData,addMyData} from "../../Redux/ducks/mainReducer";
import {Alert,Label,Row,Col,FormGroup,Input, Button,Spinner,Table} from 'reactstrap'

import ReactJsAlert from "reactjs-alert"
import jsPDF from 'jspdf';
import 'jspdf-autotable';
export class ShareCapitalSettings extends Component {
    state={
        isSaving:false,
        mainData:null,
        msg:null,
        addCatMsg:null,
        btnDisabled:false,
        errorMsg:null,
        successMsg:null,
    
        alertStatus :false, alertType :"error", alertTitle:null,
        qty:"",total:"",cost:"",details:"",staff:"",cat:"",name:"",myDate:"",
        reportData:null,getReport:false,

        balances:0,bank:0,box:0,sys:0,goodwill:0,other:0,investment:0

    }

    componentDidMount(){
        const data = new FormData();
        this.props.getData(data,"GET_CURRENTREPORT")
    }

    componentDidUpdate(prevProps){
        const{mainData,msg,mainDataStatus,addUserStatus,getReportData,addMyDataStatus,addMyData} = this.props;
        
        if(mainDataStatus !== prevProps.mainDataStatus){
            if(mainDataStatus===true){
                this.setState({isSaving:false,mainData:mainData
                })
            }
        } 

        if(addUserStatus !== prevProps.addUserStatus){
            if(addUserStatus===true){

                
                let topenLoan = 0
                let topenContribution = 0
                let topenFines = 0
                let topenWelfare = 0
                let topenIntrest = 0
                if(getReportData !==null){
                    getReportData.map((values,i) =>{
                        if(parseInt(i)===0){
                            this.setState({
                                bank:values.shareData.bank,
                                box:values.shareData.box,
                                sys:values.shareData.system,
                                goodwill:values.shareData.goodwill,
                                other:values.shareData.others,
                                investment:values.shareData.investment,
                               
                            })
                        }
                        topenLoan = topenLoan+parseInt(values.openLoan)
                        topenContribution = topenContribution+parseInt(values.openContribution)
                        topenFines = topenFines+parseInt(values.openFines)
                        topenWelfare = topenWelfare+parseInt(values.openWelfare)
                        topenIntrest = topenIntrest+parseInt(values.openIntrest)
                    
                    })
        
                }

                let totalBal = parseInt(topenLoan)+
                parseInt(topenIntrest)+parseInt(topenContribution)+
                parseInt(topenFines)+parseInt(topenWelfare)
                
                this.setState({isSaving:false,successMsg:msg,btnDisabled:false,reportData:getReportData,getReport:true,
                    balances:totalBal
                })



            }else if(addUserStatus===false){
                this.setState({isSaving:false,erroMsg:msg,btnDisabled:false,
                    alertStatus :true, alertType :"error", alertTitle:msg,getReport:false})
            }
        } 

        if(addMyDataStatus !==prevProps.addMyDataStatus){
            if(addMyDataStatus===true){
                this.setState({isSaving:false,btnDisabled:false,
                    alertStatus :true, alertType :"success", alertTitle:msg})
            }else if(addMyDataStatus===false){
                this.setState({isSaving:false,btnDisabled:false,
                    alertStatus :true, alertType :"error", alertTitle:msg})
            }
        }
    }

    setValue = e =>{
        this.setState({[e.target.name]:e.target.value})
    }

    addCategory =(e)=>{
        const {bank,box,sys,goodwill,other,investment} = this.state;
        if(bank===""||box===""||sys===""||goodwill===""||other===""||investment===""){
            this.setState({  alertStatus :true, alertType :"error", alertTitle:"Enter all feilds marked with a *"})
        }else{
            this.setState({isSaving:true,btnDisabled:true})
            const data = new FormData();
            data.append("bank",bank);
            data.append("box",box);
            data.append("sys",sys);
            data.append("goodwill",goodwill);
            data.append("other",other);
            data.append("investment",investment);
        
            /**upload new user*/
            this.props.addMyData(data,"UPDATE_SHARESCAPITAL")
        }
    }


    render() {
     
        return (
            <div>
                <div>
                    <Row className="mainWrraper">
                        <div className="block-header">
                            <h4>UPDATE CURRENT SHARE CAPITAL SETTING  </h4>
                        </div>
                    <ReactJsAlert autoCloseIn ={600000} status={this.state.alertStatus} type={this.state.alertType} title={this.state.alertTitle} Close={() => this.setState({ alertStatus: false })}/>

                    <Col xs="12" >
                        {this.state.isLoading?
                            <div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Loading...'}</div>
                        :
                        <>
                            <FormGroup>
                                <Label for="gender">Members Current Balances</Label>
                                <Input type="number" min="0"  value={this.state.balances} disabled />
                            </FormGroup>

                            <FormGroup>
                                <Label for="gender">Cash At Bank*</Label>
                                <Input type="number" min="0" onChange={this.setValue} value={this.state.bank} name="bank" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="gender">Current Investment Value*</Label>
                                <Input type="number" min="0" onChange={this.setValue} value={this.state.investment} name="investment" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="gender">Deposit Box Current Value*</Label>
                                <Input type="number" min="0" onChange={this.setValue} value={this.state.box} name="box" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="gender">System Current Value*</Label>
                                <Input type="number" min="0" onChange={this.setValue} value={this.state.sys} name="sys" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="gender">Goodwill*</Label>
                                <Input type="number" min="0" onChange={this.setValue} value={this.state.goodwill} name="goodwill" />
                            </FormGroup>

                            <FormGroup>
                                <Label for="gender">Other Incomes*</Label>
                                <Input type="number" min="0" onChange={this.setValue} value={this.state.other} name="other" />
                            </FormGroup>

                            <Col xs="12" >
                                {this.state.isSaving?<div><Spinner className="mySpinner" /><Spinner className="mySpinner" type="grow"/>{'Saving...'}</div>:null}
                                <Button onClick={this.addCategory} disabled={this.state.btnDisabled? "disabled":null} className="btn btn-block btn-lg btn-danger">Update Data</Button>
                            </Col>
                        </>
                        }
                            
                    
                    </Col>
                    
                    </Row>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state =>({
    mainDataStatus : state.mainReducer.dataStatus,
    msg : state.mainReducer.msg,
    mainData : state.mainReducer.mainData,
    getReportData : state.mainReducer.getMyData,
    addUserStatus:state.mainReducer.getMyDataStatus,
    addMyDataStatus:state.mainReducer.addMyDataStatus,
    addMyData:state.mainReducer.addMyData,
});
export default connect(
    mapStateToProps, {getMainData,getData,addMyData}
)(ShareCapitalSettings)
