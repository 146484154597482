import React from 'react';
import {Switch} from "react-router-dom";
import {Provider} from 'react-redux';
import reduxStore from './Redux/reduxStore';
import './App.css';

import AddUserCat from './Components/Pages/AddUserCategory';
import SeeUserCat from './Components/Pages/SeeUserCategory';

/** loans master */
import PrivateRoute from './Routers/PrivateRoute';
import PublicRoute from './Routers/PublicRoute';
import Login from './Components/Pages/Login';
import Dashboard from './Components/Pages/Dashboard';
import AddUser from './Components/Pages/AddUser';
import SeeUsers from './Components/Pages/SeeUsers';
import AddClient from './Components/Pages/AddClient';
import SeeClients from './Components/Pages/SeeClients';
import ActiveLoans from './Components/Pages/ActiveLoans';
import ClosedLoans from './Components/Pages/ClosedLoans';
import LoanPayments from './Components/Pages/LoanPayments';
import EditedPayments from './Components/Pages/EditedPayments';
import SoonToExpire from './Components/Pages/SoonToExpire';
import ExpiredLoans from './Components/Pages/ExpiredLoans';


import GenerateUserReport from './Components/Pages/GenerateUserReport';
import RenewedLoans from './Components/Pages/RenewedLoans';
import ViewRenewalRequests from './Components/Pages/ViewRenewalRequests';
import GenerateStaffGeneralReport from './Components/Pages/GenerateStaffGeneralReport';
import SystemSettings from './Components/Pages/SystemSettings';
import RenewalSettings from './Components/Pages/RenewalSettings';
import ExpenseCategory from './Components/Pages/ExpenseCategory';
import AddExpense from './Components/Pages/AddExpense';
import SeeExpense from './Components/Pages/SeeExpense';
import AddAccount from './Components/Pages/AddAccount';
import SeeAccounts from './Components/Pages/SeeAccounts';



import GeneralReport from './Components/Pages/GeneralReport';
import DailyReport from './Components/Pages/DailyReport';

import SeeGivenOut from './Components/Pages/SeeGivenOut';
import SeeInactiveLoans from './Components/Pages/SeeInactiveLoans';
import SeeDefaulters from './Components/Pages/SeeDefaulters';
import BusinessGraph from './Components/Pages/BusinessGraph';
import StaffGraph from './Components/Pages/StaffGraph';

import SeeBlacklist from './Components/Pages/SeeBlacklist';
import staffPerformanceReport from './Components/Pages/staffPerformanceReport';


import StaffClientList from './Components/Pages/StaffClientList';
import ReopenedLoan from './Components/Pages/ReopenedLoan';
import SeeFines from './Components/Pages/SeeFines';
import SeeLoanRequest from './Components/Pages/SeeLoanRequest';
import SetInternalControls from './Components/Pages/SetInternalControls';


import AddProcessingFee from './Components/Pages/AddProcessingFee';
import AddLoanType from './Components/Pages/AddLoanType';
import AddBusinessAssets from './Components/Pages/AddBusinessAssets';
import ActivateUser from './Components/Pages/ActivateUser';
import ApproveFine from './Components/Pages/ApproveFine';
import AprroveLoanRequest from './Components/Pages/AprroveLoanRequest';
import ApproveMonthPay from './Components/Pages/ApproveMonthPay';
import ApproveWelfare from './Components/Pages/ApproveWelfare';
import ApproveLoanPay from './Components/Pages/ApproveLoanPay';
import ClubGeneralReport from './Components/Pages/ClubGeneralReport';
import MembersReport from './Components/Pages/MembersReport';

import AddAllPayments from './Components/Pages/AddAllPayments'
import AddFine from './Components/Pages/AddFine';
import AddAllLoan from './Components/Pages/AddAllLoan';
import AllPayments from './Components/Pages/AllPayments';
import AllFines from './Components/Pages/AllFines';
import AllLoans from './Components/Pages/AllLoans';
import MonthlyReport from './Components/Pages/MonthlyReport';
import CurrentReport from './Components/Pages/CurrentReport';
import MemberReportAll from './Components/Pages/MemberReportAll';
import SeeLoanReport from './Components/Pages/SeeLoanReport';
import ShareCapitalSettings from './Components/Pages/ShareCapitalSettings';
import CalculateShareCapital from './Components/Pages/CalculateShareCapital';


function App() {
  return (
    <Provider store={reduxStore}>
      <div>
      <Switch>
          <PublicRoute  exact path='/' component={Login}/>
          <PublicRoute  exact path="/login" component={Login}/>
          <PrivateRoute exact path="/dashboard" component={Dashboard}/>
          <PrivateRoute exact path="/add_users" component={AddUser}/>
          <PrivateRoute exact path="/see_users" component={SeeUsers}/>
          <PrivateRoute exact path="/add_userCategory" component={AddUserCat}/>
          <PrivateRoute exact path="/see_userCategory" component={SeeUserCat}/>
          <PrivateRoute exact path="/add_client" component={AddClient}/>
          <PrivateRoute exact path="/see_clients" component={SeeClients}/>
          <PrivateRoute exact path="/active_loans" component={ActiveLoans}/>
          <PrivateRoute exact path="/closed_loans" component={ClosedLoans}/>
          <PrivateRoute exact path="/loan_payments" component={LoanPayments}/>
          <PrivateRoute exact path="/edited_payments" component={EditedPayments}/>
          <PrivateRoute exact path="/soon_to_expire" component={SoonToExpire}/>
          <PrivateRoute exact path="/expired_loans" component={ExpiredLoans}/>
          <PrivateRoute exact path="/generateStaffReport" component={GenerateUserReport}/>
          <PrivateRoute exact path="/renewal_requests" component={ViewRenewalRequests}/>
          <PrivateRoute exact path="/renewed_loans" component={RenewedLoans}/>
          <PrivateRoute exact path="/generateStaffGeneralReport" component={GenerateStaffGeneralReport}/>
          <PrivateRoute exact path="/systemSettings" component={SystemSettings}/>
          <PrivateRoute exact path="/renewalSettings" component={RenewalSettings}/>
          <PrivateRoute exact path="/expenseCategory" component={ExpenseCategory}/>
          <PrivateRoute exact path="/seeExpense" component={SeeExpense}/>
          <PrivateRoute exact path="/addExpense" component={AddExpense}/>
          <PrivateRoute exact path="/addAccount" component={AddAccount}/>
          <PrivateRoute exact path="/seeAccounts" component={SeeAccounts}/>
          <PrivateRoute exact path="/generalReport" component={GeneralReport}/>
          <PrivateRoute exact path="/dailyReport" component={DailyReport}/>
          <PrivateRoute exact path="/givenout_loans" component={SeeGivenOut}/>
          <PrivateRoute exact path="/inactive_loans" component={SeeInactiveLoans}/>
          <PrivateRoute exact path="/defaulted_loans" component={SeeDefaulters}/>
          <PrivateRoute exact path="/BusinessPerformance" component={BusinessGraph}/>
          <PrivateRoute exact path="/StaffPerformance" component={StaffGraph}/>
          <PrivateRoute exact path="/see_blacklistedclients" component={SeeBlacklist}/>
          <PrivateRoute exact path="/staffPerformanceReport" component={staffPerformanceReport}/>
          <PrivateRoute exact path="/staffClientList" component={StaffClientList}/>
          <PrivateRoute exact path="/reopened_loans" component={ReopenedLoan}/>
          <PrivateRoute exact path="/seeFines" component={SeeFines}/>
          <PrivateRoute exact path="/seeLoanRequest" component={SeeLoanRequest}/>
          <PrivateRoute exact path="/internalControlSettings" component={SetInternalControls}/>
        
          <PrivateRoute exact path="/addProcessingFee" component={AddProcessingFee}/>
          <PrivateRoute exact path="/addLoanType" component={AddLoanType}/>
          <PrivateRoute exact path="/addAssets" component={AddBusinessAssets}/>
          <PrivateRoute exact path="/activate_users" component={ActivateUser}/>

          <PrivateRoute exact path="/approveFine" component={ApproveFine}/>
          <PrivateRoute exact path="/approveLoan" component={AprroveLoanRequest}/>
          <PrivateRoute exact path="/approveMonthPay" component={ApproveMonthPay}/>
          <PrivateRoute exact path="/approveWelfarePay" component={ApproveWelfare}/>
          <PrivateRoute exact path="/approveLoanPay" component={ApproveLoanPay}/>
          <PrivateRoute exact path="/clubGeneralReport" component={ClubGeneralReport}/>
          <PrivateRoute exact path="/memberReport" component={MembersReport}/>
          
          <PrivateRoute exact path="/addPayment" component={AddAllPayments}/>
          <PrivateRoute exact path="/addFines" component={AddFine}/>
          <PrivateRoute exact path="/addAllLoan" component={AddAllLoan}/>
          <PrivateRoute exact path="/seeAllPayments" component={AllPayments}/>
          <PrivateRoute exact path="/seeAllFines" component={AllFines}/>
          <PrivateRoute exact path="/seeAllLoans" component={AllLoans}/>
          <PrivateRoute exact path="/monthlyReport" component={MonthlyReport}/>
          <PrivateRoute exact path="/currentReport" component={CurrentReport}/>
          <PrivateRoute exact path="/getmemberReport" component={MemberReportAll}/>
          <PrivateRoute exact path="/seeloanReport" component={SeeLoanReport}/>
          <PrivateRoute exact path="/ShareCapitalSettings" component={ShareCapitalSettings}/>
          <PrivateRoute exact path="/CalculateShareCapital" component={CalculateShareCapital}/>
          
        </Switch>
      </div>
    </Provider>
  );
}

export default App;
